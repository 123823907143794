<template>
    
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-form :inline="true">
                <el-form-item label="订单号：">
                    <el-input v-model="parent_order_sn" placeholder="请输入订单号"></el-input>
                </el-form-item>
                <el-form-item label="user_id：">
                    <el-input v-model="uid" placeholder="请输入user_id"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
                </el-form-item>
            </el-form>
        </div>
        <el-table
            v-loading="loading"
            :data="orderList"
            border
            >
            <el-table-column
                fixed
                prop="id"
                label="编号"
            >
            </el-table-column>
            <el-table-column
                prop="parent_order_sn"
                label="订单编号"
                width="180"
            >
            </el-table-column>
            <el-table-column
                label="用户user_id（user_key）"
                width="180"
            >
            <template slot-scope="scope">
                {{scope.row.user_id}}({{scope.row.user_key}})
            </template>
</el-table-column>
<el-table-column prop="shop_title" label="店铺标题" width="180px">
</el-table-column>

<el-table-column prop="pay_amount" label="商品价格" width="80"></el-table-column>
<el-table-column prop="user_commission" label="用户佣金" width="80"></el-table-column>
<el-table-column prop="subsidize_amount" label="补贴金额" width="100"></el-table-column>
<!-- 
<el-table-column label="订单状态">
    <template slot-scope="scope">
        <span :class="scope.row.status == 1?'green':scope.row.status == 3?'blue':scope.row.status==4?'warn': scope.row.status == 5||scope.row.status==6||scope.row.status==7?'red':''">
            {{scope.row.status | status}}
        </span>
    </template>
</el-table-column> -->
<el-table-column label="下单时间" width="150">
    <template slot-scope="scope">
        {{scope.row.order_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="结算时间" width="150">
    <template slot-scope="scope">
        {{scope.row.user_settled_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="审核时间" width="150">
    <template slot-scope="scope">
        {{scope.row.audit_time | dateTime}}
    </template>
</el-table-column>
<el-table-column fixed="right" label="操作">
    <template slot-scope="scope">
        <el-button v-if="scope.row.status==2" type="primary" size="mini" @click="updateSubsidies(scope.row.id,1)">通过</el-button>
        <el-button v-if="scope.row.status==2" type="danger" size="mini" @click="updateSubsidies(scope.row.id,3)">拒绝</el-button>
        <span v-if="scope.row.status==1">已通过</span>
        <span v-if="scope.row.status==3">已拒绝</span>
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>
</section>
<!-- /.content -->
</template>

<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    import * as api from '@/config/api'
    export default {
        name: 'subsidizeOrder',
        components: {},
        data() {
            return {
                orderList: [],
                total: 0,
                page: 1,
                pagesize: 10,
                payStatusList: common.order_status_list,
                uid: '',
                parent_order_sn: '',

                dialog: false,

                loading: true,

                is_next: false,
            }
        },
        watch: {},
        filters: {
            status(val) {
                let name = ''
                if (JSON.stringify(common.order_status_list).indexOf(val) != -1) {
                    common.order_status_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getSubsidiesList()
        },
        methods: {
            close() {
                this.show = false
                this.getSubsidiesList()
            },
            getSubsidiesList(page, size) {
                this.loading = true
                api.getSubsidiesList({
                    user_id: this.uid,
                    parent_order_sn: this.parent_order_sn,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.orderList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getSubsidiesList()
            },
            currentChange(val) {
                this.page = val
                this.getSubsidiesList()
            },
            seach(page, size) {
                this.getSubsidiesList(page, size)
            },
            updateSubsidies(id, status) {

                api.updateSubsidies({
                    id,
                    status
                }, res => {
                    this.$message.success('操作成功')
                    this.is_next = false
                    this.getSubsidiesList()
                })


            }
        }
    }
</script>
<style scoped>
    .el-table .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    img {
        width: 150px;
    }
    
    .red {
        color: #F56C6C
    }
    
    .blue {
        color: #409EFF
    }
    
    .green {
        color: #67C23A
    }
    
    .warn {
        color: #E6A23C
    }
</style>